import React from "react";





export default function What () {
    return(
        <div className="pageBorder">
            <p className="mainTextHead">
                Clean Food
            </p>
            <p className="mainTextBody">
                At Arkanic we are developing cutting edge Controlled Environment Agriculture (CEA).
                <br />
                <br />
                We are growing pure, natural food, isolated from all the pollutants and contaminants that have been released into the environment with industrialisation.
                <br />
                <br />
                We do not use any pesticides or insecticides, and filter everything that our plants are exposed to, from the soil to the water to the air.
                <br />
                <br />
                Unlike currently available market products, our food is free from highly toxic heavy metals, bad bacteria, and unnatural chemicals.
                <br />
                <br />
                Our food is healthier, more nutritious, and filled with more flavour.
                <br />
                <br />
                And we do all this sustainably, minimizing the use of natural resources and preserving our precious environment.
                <br />
                <br />
            </p>
            <div className="pageBorderDiv">
            </div>
            <p className="mainTextHead">
                CEA
            </p>
            <p className="mainTextBody">

                CEA is a technology where food is grown in a totally controlled environment.
                <br />
                <br />
                At our production facility, all environmental parameters that our plants grow in are controlled.
                <br />
                <br />
                The air in our unit is filtered every few minutes to remove any dust and pollution, ensuring a clean atmosphere for our plants to breathe in.
                <br />
                <br />
                We use mineral drinking water, currently Aquafina, to water our plants.
                <br />
                <br />
                The soil that the plants are grown in has been purified and carefully balanced with a mix of nutrients to ensure healthy, nutritous and safe crops.
                <br />
                <br />
                Other parameters such as temperature, humidity and pH are also computer controlled to provide the perfect environment.
                {/* <br />
                <br />
                Our facility has the highest sanitation and hygene standards. */}
                {/* // TODO:
                Add: ISO and BSL */}
                <br />

                {/* After millennia of farming humankind is yet to learn how to farm without damaging the environment.
                Farming, being the mainstay of providing the required food security is rightly so of critical
                importance and thus has been allowed to take its toll on the environment. From depleating the water resources,
                replacing forests with farm land, stripping the soil of nutrients, polluting the land with incestecides and pestisides
                etc. farming, while providing food had severely damaged the environment. This same environment where we now have to grow
                food which simply completes the cycle and reintroduces pollutants into the food chain.
                Controlled Environment Agriculture is the next step in agriculture, producing the highest quality food without impurities,
                while being sustainable and not damaging to the environment. With three of the four requirements for agriculture being stressed and damaged,
                our environment today is less and less conducive for high quality food production. By damaging our environment,
                the very crops that we grow in the same damaged environment, only recycling these pollutants back into our food chain
                while damaging the environment even further. Also with a changing climate, traditional agriculture is at much more risk than ever before. 
                <br />
                <br />
                The Arkanic one acre unit does not use any pesticides, insecticides and so is non-polluting. The crops produced in the
                unit are only supplied with filtered water, ensuring that no pollutants enter the system through the water. The air inside
                the unit is filtered and thus these crops are protected from the current polluted environment and the farm produce is of the highest purity.  */}
            </p>
        </div>
    )
}

